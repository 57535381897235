<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-24 15:05:49
 * @LastEditTime: 2020-04-24 15:07:00
 -->
<template>
  <div :class="iscompent?'enterResume iscompent':'app-container enterResume'">
    <div class="titlebox" v-if="!iscompent">
      <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
      <div class="title">{{$route.name=='EnterResume'?'录入简历':$route.name=='EditResume'?'简历编辑':`${form.name}简历详情`}}</div>
    </div>
    <section class="sections-container" v-if="!iscompent&&active==0">
      <el-steps
        space="32%"
        :active="active"
        finish-status="success"
        align-center
        v-show="this.$route.name=='EnterResume'"
        style="transform:translateX(20%)"
      >
        <el-step process-status="process " title="简历基本信息"></el-step>
        <el-step process-status="process " title="附加信息"></el-step>
      </el-steps>
      <el-form ref="form1" :model="form" label-width="220px" v-if="active=='0'">
        <div class="section-wrap">
          <h3 class="title-icon">录入条件</h3>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择岗位" prop="jobId" :rules="{ required: true,message:'请选择所属岗位',trigger:'change' }">
              <el-select v-model="form.jobId" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                  v-for="(job, i) in jobArr"
                  :key="i"
                  :label="job.jobName"
                  :value="job.jobId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划名称" prop="planName" :rules="{ required: true,message:'请选择对应计划名称' }">
              <el-input v-model="form.planName" disabled class="inputWidth"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上传简历到" prop="status" :rules="{ required: true,message:'请选择简历上传位置' }">
              <el-select v-model="form.status" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                  v-for="(status, i) in statusArr"
                  :key="i"
                  :label="status.label"
                  :value="status.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源渠道" prop="sources" :rules="{ required: true,message:'请选择来源渠道'}">
              <el-select v-model="form.sources" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                  v-for="(sources, i) in sourcesArr"
                  :key="i"
                  :label="sources.dictName"
                  :value="sources.dictId"
                  ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </section>
    <section class="sections-container">
      <el-form ref="form2" :model="form" label-width="220px">
        <div class="section-wrap">
          <h3 class="title-icon">基本信息</h3>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name" :rules="{ required: true,message: '请填写姓名',trigger: 'blur'}">
              <el-input v-model="form.name" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机" prop="phoneNumber">
              <el-input maxlength="15" v-model="form.phoneNumber" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-select style="width:100px;"
                        :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"
                        v-model="form.areaCode"
                        slot="prepend"
                        placeholder="请选择">
                <el-option label="+86"
                          value="86"></el-option>
                <el-option label="+852"
                          value="852"></el-option>
                <el-option label="+853"
                          value="853"></el-option>
                <el-option label="+886"
                          value="886"></el-option>
              </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="mailbox" :rules="{ type: 'email', message: '请输入正确的邮箱地址',  trigger: ['blur', 'change']}">
              <el-input v-model="form.mailbox" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="投递类型">
              <el-select v-model="form.postType" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                    v-for="(item, index) in sendTypeArr"
                    :key="index"
                    :label="item.dictName"
                    :value="item.dictId"
                  ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="政治面貌">
              <el-select v-model="form.politicalStatus" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                  v-for="(item,index) in politicalStatusList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="居住地">
              <el-input maxlength="20" v-model="form.homeAddress" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="婚姻状况">
              <el-select v-model="form.maritalStatus" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                  v-for="(item,index) in maritalStatusList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="民族">
              <el-select v-model="form.nationality" class="inputWidth" filterable placeholder="请选择民族" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option v-for="(item,i) in nationalOptions"
                  :key="i"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出生日期" prop="birthday" :rules="{required:true,message:'请选择出生日期',trigger:'change'}">
              <el-date-picker v-model="form.birthday" type="date" value-format="yyyy-MM-dd" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'" :picker-options="pickerOptions" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex" :rules="{required:true,message:'请选择性别',trigger:'change'}">
              <el-select v-model="form.sex" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                  v-for="(item,index) in sexArr"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="毕业院校">
             <el-select
              v-model="form.graduatedSchool"
              filterable
              class="inputWidth"
              :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"
              allow-create
              default-first-option>
              <el-option v-for="(school,i) in schoolOptions" :key="i" :value="school.value"></el-option>
            </el-select>
              <!--<el-input maxlength="20" v-model="form.graduatedSchool" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"/>!-->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参加工作时间">
              <el-date-picker v-model="form.workStartTime" type="date" value-format="yyyy-MM-dd" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'" class="inputWidth" :picker-options="pickerOption1s" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所学专业">
              <el-input maxlength="20" v-model="form.industry" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最近工作岗位">
              <el-input maxlength="20" v-model="form.recentPosition" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标签">
              <el-select v-model="form.labelIds" multiple :multiple-limit="5" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option v-for="(tag,i) in tagArr" :key="i" :value="tag.id" :label="tag.tagName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最高学历" prop="highestEducation" :rules="{required:true,message:'请选择最高学历',trigger:'change'}">
              <el-select v-model="form.highestEducation" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                  v-for="(item, index) in highestEducationList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作年限">
              <el-select v-model="form.yearsWorking" placeholder="选择年限" class="inputWidth"  :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option v-for="(item, i) in experienceList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </section>
    <section class="sections-container">
      <div class="section-wrap">
        <h3 class="title-icon">求职意向</h3>
      </div>
      <el-form ref="form" :model="form" label-width="220px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="应聘岗位">
              <el-input v-model="form.officeName" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="岗位性质">
              <el-select v-model="form.postProperties" class="inputWidth" placeholder="请选择岗位性质" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                  <el-option
                    v-for="(item, index) in postNatureList"
                    :key="index"
                    :label="item.dictName"
                    :value="item.dictId"
                  ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作地">
              <el-input v-model="form.workAddress" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="期望薪资">
              <el-input v-model="form.salaryExpectation" class="inputWidth" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="期望行业">
              <el-select v-model="form.desiredIndustry" class="inputWidth" placeholder="请选择" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option-group
                  v-for="(group,index) in industryList"
                  :key="index"
                  :label="group.label">
                  <el-option
                    v-for="(item,i1) in group.children"
                    :key="i1"
                    :label="item.label"
                    :value="item.label">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到岗期限">
              <el-select v-model="form.arriveTime" class="inputWidth" placeholder="请选择到岗期限" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'">
                <el-option
                  v-for="(item, index) in arrivalTimeLimitList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </section>
    <el-collapse v-if="active==1">
      <el-collapse-item class="viewbox" name="1">
        <template slot="title">
          <div class="titleIcon"></div>工作经历
        </template>
        <div v-for="(item, index) in form.workExperienceSaveRequests" :key="index">
          <div class="baseInfo">
            <div class="moreInfo">
              <label>工作经历{{ index + 1 }}</label>
            </div>
            <div class="editBtn">
              <el-button @click="handleAddWork('edit', item.id)" type="text"  v-if="$route.name=='EnterResume'||$route.name=='EditResume'">编辑</el-button>
            </div>
            <el-row>
              <el-col :span="18" :offset="2">
                <label class="left_label">公司名称</label>
                <label class="right_label">{{ item.company }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">部门</label>
                <label class="right_label">{{ item.departmentName }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">岗位</label>
                <label class="right_label">{{ item.officeName }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">工作开始时间</label>
                <label class="right_label">{{ item.workStartTime }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">工作结束时间</label>
                <label class="right_label">{{ item.workEndTime }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">证明人联系电话</label>
                <label class="right_label">{{item.witnessContactPhone}}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">证明人</label>
                <label class="right_label">{{ item.witness }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="22" :offset="2">
                <label class="left_label">离职原因</label>
                <label class="right_label">{{ item.quitReason }}</label>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="addBtn" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">
          <el-button icon="el-icon-plus" type="text" @click="handleAddWork('add')">添加工作经历</el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item class="viewbox" name="2">
        <template slot="title">
          <div class="titleIcon"></div>项目经验
        </template>
        <div v-for="(item, index) in form.projectExperienceSaveRequests" :key="index">
          <div class="moreInfo" style="margin-top: 15px;">
            <label>项目经历{{ index + 1 }}</label>
          </div>
          <div class="baseInfo">
            <div class="editBtn">
              <el-button @click="handleAddProject('edit', item.id)" type="text" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">编辑</el-button>
            </div>
            <el-row>
              <el-col :offset="2">
                <label class="left_label">项目名称</label>
                <label class="right_label">{{ item.proName }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :offset="2" :span="10">
                <label class="left_label">项目开始时间</label>
                <label class="right_label">{{ item.proStartTime }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">项目结束时间</label>
                <label class="right_label">{{ item.proEndTime }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :offset="2" :span="22">
                <label class="left_label">项目介绍</label>
                <label class="right_label">{{ item.proIntrod }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :offset="2" :span="22">
                <label class="left_label">职责描述</label>
                <label class="right_label">{{ item.proDuties }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :offset="2" :span="22">
                <label class="left_label">主要业绩</label>
                <label class="right_label">{{ item.proPerfor }}</label>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="addBtn" style="margin-top:15px" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">
          <el-button icon="el-icon-plus" type="text" @click="handleAddProject('add')">添加项目经历</el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item class="viewbox" name="3">
        <template slot="title">
          <div class="titleIcon"></div>教育经历
        </template>
        <div v-for="(item, index) in form.educationSaveRequests" :key="index">
          <div class="moreInfo">
            <label>教育经历{{ index + 1 }}</label>
          </div>
          <div class="baseInfo">
            <div class="editBtn">
              <el-button @click="handleAddEdu('edit', item.id)" type="text"  v-if="$route.name=='EnterResume'||$route.name=='EditResume'">编辑</el-button>
            </div>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">毕业院校</label>
                <label class="right_label">{{ item.graduatedSchool }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">专业</label>
                <label class="right_label">{{ item.profession }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">学历</label>
                <label class="right_label">{{ item.education }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">教学方式</label>
                <label class="right_label">
                  {{
                  item.educationMethodName
                  }}
                </label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :offset="2" :span="10">
                <label class="left_label">入学时间</label>
                <label class="right_label">{{ item.admissionTime }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">毕业时间</label>
                <label class="right_label">{{ item.graduationTime }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">是否第一学历</label>
                <label class="right_label">
                  {{
                  item.whetherFirEdu == "1" ? "是" : "否"
                  }}
                </label>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="addBtn" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">
          <el-button icon="el-icon-plus" type="text"  @click="handleAddEdu('add')">添加教育经历</el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item class="viewbox" name="4">
        <template slot="title">
          <div class="titleIcon"></div>培训记录
        </template>
        <div v-for="(item, index) in form.trainingSaveRequests" :key="index">
          <div class="moreInfo">
            <label>培训记录{{ index + 1 }}</label>
          </div>
          <div class="baseInfo">
            <div class="editBtn">
              <el-button @click="handleAddTraining('edit', item.id)" type="text" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">编辑</el-button>
            </div>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">培训课程</label>
                <label class="right_label">{{ item.traCourse }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">培训开始时间</label>
                <label class="right_label">{{ item.traStartTime }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">工作结束时间</label>
                <label class="right_label">{{ item.traEndTime }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">培训时长</label>
                <label class="right_label">{{ item.traTime }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :offset="2" :span="10">
                <label class="left_label">培训机构名称</label>
                <label class="right_label">{{ item.traOrgName }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">考核结果</label>
                <label class="right_label">{{ item.exaResults }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">培训地点</label>
                <label class="right_label">{{ item.traAddress }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">培训方式</label>
                <label class="right_label">
                  <el-tooltip placement="top" effect="light" popper-class="popper_class">
                    <div slot="content" class="popper_class">{{ item.traMode }}</div>
                    <div class="quit_reason">{{ item.traMode }}</div>
                  </el-tooltip>
                </label>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="addBtn" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">
          <el-button icon="el-icon-plus" type="text" @click="handleAddTraining('add')">添加培训经历</el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item class="viewbox" name="5">
        <template slot="title">
          <div class="titleIcon"></div>自我评价
        </template>
        <el-form ref="form" :model="form" label-width="220px">
          <el-row>
            <el-col :span="22">
              <el-form-item label="自我评价">
                <el-input v-model="form.selfAssessment" :disabled="$route.name!='EnterResume'&&$route.name!='EditResume'" type="textarea" :rows="3" show-word-limi :maxlength="1000"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-collapse-item>
      <el-collapse-item class="viewbox" name="6">
        <template slot="title">
          <div class="titleIcon"></div>证书记录
        </template>
        <div v-for="(item, index) in form.certificateSaveRequests" :key="index">
          <div class="moreInfo">
            <label>证书{{ index + 1 }}</label>
          </div>
          <div class="baseInfo">
            <div class="editBtn">
              <el-button @click="handleAddCertificates('edit', item.id)" type="text" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">编辑</el-button>
            </div>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">证书名称</label>
                <label class="right_label">{{ item.cerName }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">等级</label>
                <label class="right_label">{{ item.grade }}</label>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10" :offset="2">
                <label class="left_label">分数</label>
                <label class="right_label">{{ item.score || "" }}</label>
              </el-col>
              <el-col :span="12">
                <label class="left_label">颁发时间</label>
                <label class="right_label">{{ item.startTime }}</label>
              </el-col>
            </el-row>
            <!-- <div>
                    <div>
                      <label class="left_label">结束时间</label>
                      <label class="right_label">{{item.endTime}}</label>
                    </div>
            </div>-->
          </div>
        </div>
        <div class="addBtn" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">
          <el-button icon="el-icon-plus" type="text" @click="handleAddCertificates('add')">添加证书记录</el-button>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-row style="margin-bottom:-2%" class="sections-container" v-if="$route.name=='EnterResume'||$route.name=='EditResume'">
      <el-col :offset="11" :span="2">
        <el-button @click="back">{{active==1?'上一步':'取消'}}</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="submit">{{active==0?'下一步':'提交'}}</el-button>
      </el-col>
    </el-row>
    <el-dialog :title="workTitle" :visible.sync="workVisable" width="70%">
      <el-form
        :model="workParams"
        :label-position="labelPosition"
        :rules="rules"
        ref="workParams"
        label-width="140px"
        class="demo-form-inline formItem"
      >
        <el-form-item label="公司名称" style="font-weight:normal" prop="company">
          <el-input v-model="workParams.company" style="width:42%"></el-input>
        </el-form-item>
        <el-col :span="12">
          <el-form-item label="部门" :span="12" prop="departmentName">
            <el-input v-model="workParams.departmentName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="岗位" prop="officeName">
            <el-input v-model="workParams.officeName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工作开始时间" prop="workStartTime">
            <el-date-picker
              :picker-options="graduationOptions"
              v-model="workParams.workStartTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工作结束时间" prop="workEndTime">
            <el-date-picker
              :picker-options="graduationOptions"
              v-model="workParams.workEndTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="离职原因" class="work">
            <el-input
              v-model="workParams.quitReason"
              type="textarea"
              maxlength="255"
              show-word-limit
              rows="4"
              class="input_textarea"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证明人">
            <el-input v-model="workParams.witness"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证明人联系方式">
            <el-input v-model="workParams.witnessContactPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer" class="dialog-footer" style="clear:both;">
        <el-button @click="workVisable = false">取 消</el-button>
        <el-button type="primary" @click="saveStaffWorkExperience">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="projectTitle" :visible.sync="projectVisable" width="70%">
      <el-form
        :model="projectParams"
        :label-position="labelPosition"
        :rules="rules"
        ref="projectParams"
        label-width="140px"
        class="demo-form-inline formItem"
      >
        <el-form-item label="项目名称" prop="proName" style="font-weight:normal">
          <el-input v-model="projectParams.proName" style="width:42%"></el-input>
        </el-form-item>

        <el-col :span="12">
          <el-form-item prop="proStartTime" label="项目开始时间">
            <el-date-picker
              :picker-options="graduationOptions"
              v-model="projectParams.proStartTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="proEndTime" label="项目结束时间">
            <el-date-picker
              :picker-options="graduationOptions"
              v-model="projectParams.proEndTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="项目介绍" class="work" prop="proIntrod">
            <el-input
              v-model="projectParams.proIntrod"
              type="textarea"
              maxlength="255"
              resize="none"
              show-word-limit
              rows="5"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="proDuties" label="职责描述" class="work">
            <el-input
              v-model="projectParams.proDuties"
              type="textarea"
              maxlength="255"
              resize="none"
              show-word-limit
              rows="5"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="proPerfor" label="主要业绩" class="work">
            <el-input
              v-model="projectParams.proPerfor"
              type="textarea"
              maxlength="255"
              resize="none"
              show-word-limit
              rows="5"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer" class="dialog-footer" style="clear:both;">
        <el-button @click="projectVisable = false">取 消</el-button>
        <el-button type="primary" @click="saveStaffProjectExperience">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="trainingTitle" :visible.sync="trainingVisable" width="70%">
      <el-form
        :model="trainingParams"
        :label-position="labelPosition"
        :rules="rules"
        ref="trainingParams"
        label-width="120px"
        :inline="true"
        class="demo-form-inline formItem trainingForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="培训课程" style="font-weight:normal" prop="traCourse">
              <el-input v-model="trainingParams.traCourse"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培训开始时间" prop="traStartTime">
              <!-- @change="getDateDiff" -->
              <el-date-picker
                :picker-options="graduationOptions"
                v-model="trainingParams.traStartTime"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="date"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="培训结束时间" prop="traEndTime">
              <!-- @change="getDateDiff" -->
              <el-date-picker
                :picker-options="graduationOptions"
                v-model="trainingParams.traEndTime"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培训时长">
              <el-input
                oninput="value=value.replace(/[^\d.]/g,'')"
                v-model="trainingParams.traTime"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="培训机构名称" prop="traOrgName">
              <el-input v-model="trainingParams.traOrgName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核结果">
              <el-input v-model="trainingParams.exaResults"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="培训地点">
              <el-input v-model="trainingParams.traAddress"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="培训方式" class="input_textarea">
              <el-input
                maxlength="64"
                type="textarea"
                show-word-limit
                v-model="trainingParams.traMode"
                class="input_area"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="clear:both;">
        <el-button @click="trainingVisable = false">取 消</el-button>
        <el-button type="primary" @click="saveStafftrainingRecordsInfo">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="eduTitle" :visible.sync="eduVisable" width="70%">
      <el-form
        :model="eduParams"
        :label-position="labelPosition"
        ref="eduParams"
        :rules="rules"
        label-width="120px"
        :inline="true"
        class="demo-form-inline formItem trainingForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="毕业院校" prop="graduatedSchool" style="font-weight:normal">
              <el-input v-model="eduParams.graduatedSchool" style="width: 192px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业"  prop="profession">
              <el-input v-model="eduParams.profession" style="width: 192px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="学历" prop="education">
              <el-select v-model="eduParams.education"  style="width: 240px;">
                <el-option
                  v-for="(item, index) in highestEducationList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="教学方式" >
              <el-select v-model="eduParams.educationMethod"  style="width: 240px;">
                <el-option
                  v-for="(item, index) in educationMethodList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="入学时间">
              <el-date-picker
                :picker-options="graduationOptions"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                v-model="eduParams.admissionTime"
                type="date"
                style="width: 192px;"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="毕业时间">
              <el-date-picker
                :picker-options="graduationOptions"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                v-model="eduParams.graduationTime"
                type="date"
                style="width: 192px;"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否第一学历" prop="whetherFirEdu">
              <el-select v-model="eduParams.whetherFirEdu"  style="width: 240px;">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="clear:both;">
        <el-button @click="eduVisable = false">取 消</el-button>
        <el-button type="primary" @click="saveStaffEducationInfo">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="certificatesTitle" :visible.sync="certificatesVisible" width="40%">
      <el-form
        :model="certificatesParams"
        :label-position="labelPosition"
        :rules="rules"
        ref="certificatesParams"
        label-width="80px"
        class="demo-form-inline formItem"
      >
        <el-col :span="12">
          <el-form-item label="证书名称" style="font-weight:normal" prop="cerName">
            <el-input v-model="certificatesParams.cerName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="等级">
            <el-input v-model="certificatesParams.grade"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分数">
            <el-input
              type="number"
              oninput="value=value.replace(/[^\d.]/g,'')"
              v-model="certificatesParams.score"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="颁发时间" :span="12">
            <el-date-picker
              :picker-options="graduationOptions"
              v-model="certificatesParams.startTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
                <el-form-item label="结束时间" >
                      <el-date-picker v-model="certificatesParams.endTime" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" style="width: 100%;"></el-date-picker>
                    </el-form-item>
        </el-col>-->
      </el-form>
      <div slot="footer" class="dialog-footer" style="clear:both;">
        <el-button @click="certificatesVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveStaffCertificate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  resumeDetail,
  recruitStationList,
  resumeSave,
  resumeWorkExperienceSave,
  resumeProjectExperienceSave,
  resumeCertificateSave,
  resumeEducationSave,
  resumeTrainingtSave,
  tagList
} from '../../../api/recruitment_allocation';
export default {
  props: {
    iscompent: {
      type: Boolean,
      default: false
    },
    resumeId: {
      type: String
    }
  },
  data() {
    return {
      form: {
        id: '',
        recruitId: '',
        planName: '',
        resumeId: '',
        planId: '',
        jobId: '',
        status: '',
        mailbox: '',
        name: '',
        phoneNumber: '',
        areaCode: '86',
        postType: '',
        politicalStatus: '',
        homeAddress: '',
        maritalStatus: '',
        nationality: '',
        birthday: '',
        sex: '',
        sources: '',
        industry: '',
        workStartTime: '',
        graduatedSchool: '',
        recentPosition: '',
        highestEducation: '',
        selfAssessment: '',
        yearsWorking: '',
        labelIds: [],
        officeName: '',
        postProperties: '',
        workAddress: '',
        salaryExpectation: '',
        desiredIndustry: '',
        arriveTime: '',
        workExperienceSaveRequests: [],
        projectExperienceSaveRequests: [],
        trainingSaveRequests: [],
        educationSaveRequests: [],
        certificateSaveRequests: []
      },
      active: this.$route.name != 'EnterResume' ? 1 : 0,
      planArr: [],
      jobArr: [],
      sourcesArr: [],
      maritalStatusList: [],
      arrivalTimeLimitList: [],
      highestEducationList: [],
      experienceList: [],
      politicalStatusList: [],
      educationMethodList: [],
      postNatureList: [],
      sexArr: [],
      tagArr: [],
      sendTypeArr: [],
      pickerOptions: {
        disabledDate: (time) => time.getTime() + 86400000 > new Date()
      },
      pickerOption1s: {
        disabledDate: (time) => {
          if (this.form.birthday) {
            return time.getTime() < new Date(this.form.birthday).setFullYear((new Date(this.form.birthday).getFullYear() + 15));
          }
            return time.getTime() + 86400000 > new Date();

        }
      },
      statusArr: [
        { value: '1', label: '简历管理' },
        { value: '2', label: '备选' }
      ],
      projectParams: {
        id: '',
        staffId: '',
        proName: '',
        proIntrod: '',
        proDuties: '',
        proPerfor: '',
        proStartTime: '',
        proEndTime: ''
      },
      workParams: {
        id: '',
        staffId: '',
        company: '',
        officeName: '',
        witness: '',
        witnessContactPhone: '',
        quitReason: '',
        departmentName: '',
        workStartTime: '',
        workEndTime: ''
      },
      trainingParams: {
        id: '',
        staffId: '',
        traCourse: '',
        traStartTime: '',
        traEndTime: '',
        traTime: '',
        traOrgName: '',
        exaResults: '',
        traAddress: '',
        traMode: ''
      },
      eduParams: {
        id: '',
        staffId: '',
        graduatedSchool: '',
        profession: '',
        education: '',
        educationMethod: '',
        whetherFirEdu: '1',
        admissionTime: '',
        graduationTime: ''
      },
      certificatesParams: {
        id: '',
        staffId: '',
        cerName: '',
        grade: '',
        score: '',
        startTime: '',
        endTime: ''
      },
      rules: {
        identityNum: [
          { required: true, message: '请输入内容', trigger: 'blur' },
          {
            pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: '证件号码格式有误！',
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            pattern: /^\d{3,11}$|^\d{3,11}$/,
            message: '请输入正确手机号码',
            trigger: 'blur'
          }
        ],
        maritalStatus: [
          { required: true, message: '请选择婚姻状态', trigger: 'change' }
        ],
        mailbox: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        highestEducation: [
          { required: true, message: '请选择学历', trigger: 'change' }
        ],
        homeAddress: [
          { required: true, message: '请输入家庭住址', trigger: 'blur' }
        ],
        nativePlace: [
          { required: true, message: '请输入籍贯', trigger: 'blur' }
        ],
        emergencyContact: [
          { required: true, message: '请输入紧急联系人', trigger: 'blur' }
        ],
        emergencyContactPhone: [
          { required: true, message: '请输入紧急联系人电话', trigger: 'blur' }
        ],
        politicalStatus: [
          { required: true, message: '请输入政治面貌', trigger: 'blur' }
        ],
        registeredResidenceAddress: [
          { required: true, message: '请输入户口所在', trigger: 'blur' }
        ],
        workAddress: [
          { required: true, message: '请输入工作地址', trigger: 'blur' }
        ],
        nationality: [
          { required: true, message: '请输入民族', trigger: 'blur' }
        ],
        graduatedSchool: [
          { required: true, message: '请输入毕业院校', trigger: 'blur' }
        ],
        education: [
          { required: true, message: '请选择学历', trigger: 'change' }
        ],
        profession: [
          { required: true, message: '请输入专业', trigger: 'blur' }
        ],
        whetherFirEdu: [
          { required: true, message: '请选择是否第一学历', trigger: 'change' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        officeName: [
          { required: true, message: '请输入职位', trigger: 'blur' }
        ],
        departmentName: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ],
        workStartTime: [
          {
            type: 'string',
            required: true,
            message: '请选择开始时间',
            trigger: 'change'
          }
        ],
        workEndTime: [
          {
            type: 'string',
            required: true,
            message: '请选择结束时间',
            trigger: 'change'
          }
        ],
        proName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        proIntrod: [
          { required: true, message: '请输入项目介绍', trigger: 'blur' }
        ],
        proStartTime: [
          {
            type: 'string',
            required: true,
            message: '请选择开始时间',
            trigger: 'change'
          }
        ],
        proEndTime: [
          {
            type: 'string',
            required: true,
            message: '请选择结束时间',
            trigger: 'change'
          }
        ],
        proDuties: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        proPerfor: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        traCourse: [
          { required: true, message: '请输入培训课程', trigger: 'blur' }
        ],
        traStartTime: [
          {
            type: 'string',
            required: true,
            message: '请选择开始时间',
            trigger: 'change'
          }
        ],
        traEndTime: [
          {
            type: 'string',
            required: true,
            message: '请选择结束时间',
            trigger: 'change'
          }
        ],
        traOrgName: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        relationship: [
          { required: true, message: '请输入家属关系', trigger: 'blur' }
        ],
        isUrgent: [
          { required: true, message: '请选择是否紧急联系人', trigger: 'change' }
        ],
        telOne: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^\d{3,11}$|^\d{3,11}$/,
            message: '请输入正确手机号码',
            trigger: 'blur'
          }
        ],
        cerName: [
          { required: true, message: '请输入证书名称', trigger: 'blur' }
        ],
        entryTime: [
          {
            type: 'string',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        businessMailbox: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        turnPositiveTime: [
          {
            type: 'string',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        status: [
          { required: true, message: '请选择转正状态', trigger: 'change' }
        ],
        hireForm: [
          { required: true, message: '请选择聘用形式', trigger: 'change' }
        ],
        orgId: [
          { required: true, message: '请选择组织部门', trigger: 'change' }
        ],
        jobEtc: [{ required: true, message: '请选择职等', trigger: 'change' }],
        positionId: [
          { required: true, message: '请选择职务', trigger: 'change' }
        ],
        officeId: [
          { required: true, message: '请选择职位', trigger: 'change' }
        ],
        jobId: [{ required: true, message: '请选择岗位', trigger: 'change' }],
        centerId: [
          { required: true, message: '请选择中心', trigger: 'change' }
        ],
        repObjId: [
          { required: true, message: '请选择汇报对象', trigger: 'change' }
        ],
        workCity: [
          { required: true, message: '请选择工作地址', trigger: 'change' }
        ],
        firstWorkTime: [
          {
            type: 'string',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        duty: [{ required: true, message: '请输入职责', trigger: 'blur' }],
        business: [{ required: true, message: '请输入业务', trigger: 'blur' }],
        hpfName: [
          { required: true, message: '请选择公积金城市', trigger: 'change' }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        renewNumber: [
          {
            type: 'number',
            required: true,
            message: '请输入签约次数',
            trigger: 'blur'
          }
        ],
        deadline: [
          { required: true, message: '请选择期限', trigger: 'change' }
        ],
        contractStartTime: [
          {
            type: 'string',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        contractEndTime: [
          {
            type: 'string',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ]
      },
      graduationOptions: {
        disabledDate: (time) => time.getTime() > Date.now()
      },
      schoolOptions: window.$schoolList,
      industryList: window.$industryList,
      nationalOptions: window.$nationalList,
      workTitle: '',
      workVisable: false,
      projectTitle: '',
      projectVisable: false,
      trainingTitle: '',
      trainingVisable: false,
      eduTitle: '',
      eduVisable: false,
      certificatesVisible: false,
      certificatesTitle: '',
      labelPosition: 'right',
      isShowEdit: true
    };
  },
  watch: {
    'form.jobId': {
      handler(val) {
        if (val) {
          this.jobToplan(val);
        }
      },
      deep: true
    }
  },
  methods: {
    getJob() {
      recruitStationList({ status: '1', pageNo: 1, pageSize: 9999}).then((res) => {
        if (res._responseStatusCode == 0) {
          res.list.forEach((it) => {
            this.jobArr.push({recruitId: it.id, ...it});
          });
        }
      });
    },
    jobToplan(val) {
      this.jobArr.map((it) => {
        if (it.jobId == val) {
          this.form.recruitId = it.recruitId;
          this.form.planId = it.planId;
          this.form.officeName = it.jobName;
          this.form.planName = it.planName;
        }
      });
    },
    back() {
      if (this.active == 0) {
        this.$router.push({ name: 'Resumelibrary' });
      } else {
        this.active--;
      }
    },
    submit() {
      if (this.active == 0) {
        this.$refs.form1.validate((validate) => {
        if (validate) {
          this.$refs.form2.validate((validate) => {
            if (validate) {
              resumeSave(this.form).then((res) => {
                if (res && !res.code) {
                  if (this.active == 0) {
                    this.form.resumeId = res.id;
                    this.form.id = res.id;
                    this.active++;
                  } else {
                    this.$router.push({ name: 'Resumelibrary' });
                  }
                  this.$message.success('简历保存成功');
                }
              });
            }
          });
        }
      });
      } else {
        this.$refs.form2.validate((validate) => {
          if (validate) {
            resumeSave(this.form).then((res) => {
              if (res && !res.code) {
                if (this.active == 0) {
                  this.form.resumeId = res.id;
                  this.form.id = res.id;
                  this.active++;
                } else {
                  this.$router.push({ name: 'Resumelibrary' });
                }
                this.$message.success('简历保存成功');
              }
            });
          }
        });
      }
    },
    // 添加、编辑工作经历
    handleAddWork(type, id) {
      this.workVisable = true;
      this.workParams.id = id;
      if (type === 'add') {
        // 移除表单验证
        if (this.$refs['workParams']) {
          this.$refs['workParams'].clearValidate();
          this.$refs['workParams'].resetFields();
        }
        this.workTitle = '添加工作经历';
        this.workParams = {
          id: '',
          company: '',
          resumeId: this.form.id,
          officeName: '',
          witness: '',
          witnessContactPhone: '',
          quitReason: '',
          departmentName: '',
          workStartTime: '',
          workEndTime: ''
        };
      } else {
        this.workTitle = '修改工作经历';
        // 移除表单验证
        if (this.$refs['workParams']) {
          this.$refs['workParams'].clearValidate();
        }
        let works = this.form.workExperienceSaveRequests.filter((item) => item.id == id);

        works = JSON.stringify(works[0]);
        this.workParams = JSON.parse(works);
      }
      this.workParams.staffId = this.staffId;
    },
    // 添加、编辑教育经历
    handleAddEdu(type, id) {
      this.eduVisable = true;
      this.eduParams.id = id;
      if (type === 'add') {
        this.eduTitle = '添加教育经历';
        // 移除表单验证
        if (this.$refs['eduParams']) {
          this.$refs['eduParams'].clearValidate();
          this.$refs['eduParams'].resetFields();
        }
        // this.$refs['eduParams'].resetFields();
        this.eduParams = {
          id: '',
          resumeId: this.form.id,
          graduatedSchool: '',
          profession: '',
          education: '',
          educationMethod: '',
          whetherFirEdu: '1',
          admissionTime: '',
          graduationTime: ''
        };
      } else {
        this.eduTitle = '修改教育经历';
        // 移除表单验证
        if (this.$refs['eduParams']) {
          this.$refs['eduParams'].clearValidate();
        }
        let edus = this.form.staffeducationSaveRequests.filter((item) => item.id == id);

        edus = JSON.stringify(edus[0]);
        this.eduParams = JSON.parse(edus);
      }
      this.eduParams.staffId = this.staffId;
    },
    // 添加、编辑培训经历
    handleAddTraining(type, id) {
      this.trainingVisable = true;
      this.trainingParams.id = id;
      if (type === 'add') {
        // 移除表单验证
        if (this.$refs['trainingParams']) {
          this.$refs['trainingParams'].clearValidate();
          this.$refs['trainingParams'].resetFields();
        }
        this.trainingTitle = '添加培训经历';
        this.trainingParams = {
          id: '',
          resumeId: this.form.id,
          traCourse: '',
          traStartTime: '',
          traEndTime: '',
          traTime: '',
          traOrgName: '',
          exaResults: '',
          traAddress: '',
          traMode: ''
        };
      } else {
        this.trainingTitle = '修改培训经历';
        // 移除表单验证
        if (this.$refs['trainingParams']) {
          this.$refs['trainingParams'].clearValidate();
        }
        let project = this.form.trainingSaveRequests.filter((item) => item.id == id);

        project = JSON.stringify(project[0]);
        this.trainingParams = JSON.parse(project);
      }
      this.trainingParams.staffId = this.staffId;
    },
    // 添加、编辑项目经历
    handleAddProject(type, id) {
      this.projectVisable = true;
      this.projectParams.id = id;
      if (type === 'add') {
        this.projectTitle = '添加项目经历';
        // 移除表单验证
        if (this.$refs['projectParams']) {
          this.$refs['projectParams'].clearValidate();
          this.$refs['projectParams'].resetFields();
        }
        this.projectParams = {
          id: '',
          resumeId: this.form.id,
          proName: '',
          proIntrod: '',
          proDuties: '',
          proPerfor: '',
          proStartTime: '',
          proEndTime: ''
        };
      } else {
        // 移除表单验证
        if (this.$refs['projectParams']) {
          this.$refs['projectParams'].clearValidate();
        }
        this.projectTitle = '修改项目经历';
        let project = this.form.projectExperienceSaveRequests.filter((
          item
        ) => item.id == id);

        project = JSON.stringify(project[0]);
        this.projectParams = JSON.parse(project);
      }
      this.projectParams.staffId = this.staffId;
    },
    // 添加、编辑证书记录
    handleAddCertificates(type, id) {
      this.certificatesVisible = true;
      this.certificatesParams.id = id;
      if (type === 'add') {
        if (this.$refs['certificatesParams']) {
          this.$refs['certificatesParams'].clearValidate();
          this.$refs['certificatesParams'].resetFields();
        }
        this.certificatesTitle = '添加证书记录';
        this.certificatesParams = {
          id: '',
          resumeId: this.form.id,
          cerName: '',
          grade: '',
          score: '',
          startTime: '',
          endTime: ''
        };
      } else {
        if (this.$refs['certificatesParams']) {
          this.$refs['certificatesParams'].clearValidate();
        }
        this.certificatesTitle = '修改证书记录';
        let certificates = this.form.certificateSaveRequests.filter(
          (item) => item.id == id
        );

        certificates = JSON.stringify(certificates[0]);
        this.certificatesParams = JSON.parse(certificates);
      }
      this.certificatesParams.staffId = this.staffId;
    },
    // 保存工作经历
    saveStaffWorkExperience() {
      this.$refs['workParams'].validate((valid) => {
        if (valid) {
          const start = new Date(this.workParams.workStartTime).getTime();

          const end = new Date(this.workParams.workEndTime).getTime();

          if (start > end) {
            this.$message.error('开始时间不能大于结束时间');
            return;
          }
          resumeWorkExperienceSave(this.workParams)
            .then((result) => {
              if (result.code == 0) {
                this.workVisable = false;
                this.$message.success('工作经历保存成功');
                this.getResumeInfo();
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    // 保存项目经验
    saveStaffProjectExperience() {
      this.$refs['projectParams'].validate((valid) => {
        if (valid) {
          const start = new Date(this.projectParams.proStartTime).getTime();

          const end = new Date(this.projectParams.proEndTime).getTime();

          if (start > end) {
            this.$message.error('开始时间不能大于结束时间');
            return;
          }
          resumeProjectExperienceSave(this.projectParams)
            .then((result) => {
              if (result.code == 0) {
                this.projectVisable = false;
                this.$message.success('工作经历保存成功');
                this.getResumeInfo();
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    // 保存培训记录
    saveStafftrainingRecordsInfo() {
      this.$refs['trainingParams'].validate((valid) => {
        if (valid) {
          const start = new Date(this.trainingParams.traStartTime).getTime();

          const end = new Date(this.trainingParams.traEndTime).getTime();

          if (start > end) {
            this.$message.error('开始时间不能大于结束时间');
            return;
          }
          resumeTrainingtSave(this.trainingParams)
            .then((result) => {
              if (result.code == 0) {
                this.trainingVisable = false;
                this.$message.success('培训记录保存成功');
                this.getResumeInfo();
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    // 保存教育经历
    saveStaffEducationInfo() {
      this.$refs['eduParams'].validate((valid) => {
        if (valid) {
          const admissionTime = new Date(this.eduParams.admissionTime).getTime();

          const graduationTime = new Date(
            this.eduParams.graduationTime
          ).getTime();

          if (admissionTime > graduationTime) {
            this.$message.error('入学时间不能大于毕业时间');
            return;
          }
          resumeEducationSave(this.eduParams)
            .then((result) => {
              if (result.code == 0) {
                this.eduVisable = false;

                this.getResumeInfo();
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    getResumeInfo() {
      resumeDetail({ id: this.$route.params.id || this.resumeId || this.form.resumeId }).then((res) => {
        for (const key1 in res) {
          for (const key2 in this.form) {
            if (key1 == key2 && res[key1]) {
              this.form[key2] = res[key1];
            }
          }
        }
        if (res.labelIdList) {
          const _list = [];

          res.labelIdList.map((item) => _list.push(Number(item)));
          this.form.labelIds = _list;
        }
      });
    },
    // 保存证书记录
    saveStaffCertificate() {
      this.$refs['certificatesParams'].validate((valid) => {
        if (valid) {
          resumeCertificateSave(this.certificatesParams)
            .then((result) => {
              if (result.code == 0) {
                this.certificatesVisible = false;
                this.getResumeInfo();
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    }
  },
  created() {
    this.maritalStatusList = this.$store.state.app.dict.filter((item) => item.groupId == 'maritalStatus');
    this.arrivalTimeLimitList = this.$store.state.app.dict.filter((item) => item.groupId == 'arrivalTimeLimit');
    this.postNatureList = this.$store.state.app.dict.filter((item) => item.groupId == 'postNature');
    this.sourcesArr = this.$store.state.app.dict.filter((item) => item.groupId == 'fromType');
    this.highestEducationList = this.$store.state.app.dict.filter((
      item
    ) => item.groupId == 'highestEducation');
    this.politicalStatusList = this.$store.state.app.dict.filter((
      item
    ) => item.groupId == 'politicalStatus');
    this.sexArr = this.$store.state.app.dict.filter((item) => item.groupId == 'sex');
    this.experienceList = this.$store.state.app.dict.filter((item) => item.groupId == 'experience');
    this.educationMethodList = this.$store.state.app.dict.filter((
      item
    ) => item.groupId == 'educationMethod');
    this.sendTypeArr = this.$store.state.app.dict.filter((item) => item.groupId == 'sendType');
    tagList({}).then((res) => {
      if (res) {
        this.tagArr = res;
      }
    });
  },
  mounted() {
    this.getJob();
    // if (this.$route.name!='EnterResume') {
    //   this.getResumeInfo();
    // }
    this.form = this.$route.params.detail;
  }
};
</script>

<style lang="scss">
.enterResume {
  label {
    font-weight: normal;
  }
  .sections-container {
    overflow: hidden;
    margin-bottom: 10px;
    .title-icon {
      font-size: 16px;
      margin: 0 0 20px 10px;
      &:before {
        display: inline-block;
        width: 8px;
        height: 18px;
        background: #498df0;
        content: " ";
        border-radius: 5px;
        vertical-align: top;
        margin-right: 5px;
      }
    }
  }
  .sections-container {
    border-radius: 4px;
    padding: 40px 60px;
    margin: 10px 18px;
    background-color: #fff;
  }
  .titlebox {
    text-align: center;
    line-height: 17px;
    background: #fff;
    font: bold 18px/25px "Microsoft YaHei";
    padding: 14px 40px;
    margin: 20px;
    > .el-button {
      float: left;
      color: #484848;
      transform: translateY(-8px);
      font-size: 16px;
    }
  }
  .viewbox {
    padding: 0px;
    margin: 0 20px;
    .el-collapse {
      border-top: none;
      border-bottom: none;
      margin-bottom: 10px;
    }
    .el-collapse-item__header {
      margin: 5px 0px 0px 0px;
      border-bottom: none;
      font-size: 16px;
      font-weight: bold;
      padding: 0px 15px;
      height: 75px;
    }
    .baseInfo {
      font-size: 14px;
      .editBtn {
        text-align: right;
        padding-right: 25px;
      }
      div {
        min-height: 50px;
      }
      .viewdiv {
        width: 50%;
        display: inline-block;
      }
      .left_label {
        margin-right: 30px;
        color: #999999;
        text-align: right;
        width: 130px;
        vertical-align: top;
      }
      .right_label {
        width: 50%;
        overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        text-align: left;
      }
      label {
        font-weight: normal;
        display: inline-block;
      }
    }
    .moreInfo {
      font-size: 14px;
      font-weight: bold;
      -webkit-transform: translateY(-3px);
      transform: translate(80px, -3px);
      padding: 0;
      float: left;
      label {
        margin-top: 10px;
      }
      .editBtn {
        position: absolute;
        top: 8px;
        right: 0;
      }
    }
  }
  .trainingForm {
    .el-form-item {
      width: 80%;
      .el-form-item__content {
        width: 65%;
        .el-input,
        .el-date-editor {
          width: 80%;
        }
        .el-textarea {
          width: 100%;
        }
      }
    }
  }
  .titleIcon {
    display: inline-block;
    width: 8px !important;
    height: 20px;
    background: #498df0;
    border-radius: 5px;
    vertical-align: bottom;
    margin-right: 5px !important;
  }
  .titlebox {
    text-align: center;
    line-height: 17px;
    background: #fff;
    font: bold 18px/25px "Microsoft YaHei";
    padding: 14px 40px;
    margin: 20px;
    > .el-button {
      float: left;
      color: #484848;
      transform: translateY(-8px);
      font-size: 16px;
    }
  }
  .moreInfo {
    font-size: 14px;
    font-weight: bold;
    margin: 0px 15px;
    padding-top: 15px;
    position: relative;
    label {
      margin-top: 10px;
    }
    .editBtn {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
  .addBtn {
    border: 1px dashed #ccc;
    text-align: center;
    height: 80px;
    margin: 0px 15px;
    padding: 20px 0px;
    font-size: 16px;
    &:hover {
      cursor: pointer;
    }
  }
}
.iscompent {
  .sections-container,.viewbox{
    margin: 0;
  }
}
</style>
